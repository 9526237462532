import React, { useState } from 'react';
import Merchants_Navbar from '../../components/Merchants_Navbar';
import Layout from '../../components/Layout';
import Modal from '../../components/Modal';

function Index(props) {
    const [modalOpen, setModal] = useState(false)
    const closeModal = () => {
        setModal(false)
    }
    let styles ={
        section2:{
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649688844/cwa/assets/images/Merchants/merchant-about-bg_t8rpln.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }
    }
    return (
        <div>
            <Layout location={'Merchants'} title={'Doing More for Merchants'}>
            {modalOpen ? <Modal fill="Merchant" closeFn={closeModal} />:null}
            <Merchants_Navbar location={'index'}/>
            <section className="cw-bg-bluegradient text-center py-10 px-5">
                <div className="text-center max-w-2xl mx-auto">
                    <h1 className="text-5xl text-white font-bold pb-4">Doing More for Merchants</h1>
                    <h2 className="section-title font-semibold mb-3 text-white">Reliable Credit Card Processing from CWA</h2>
                </div>
            </section>
            <section className="py-20 text-center w-100" style={styles.section2}>
                <div className="max-w-2xl mx-auto px-5">
                    <h2 className="section-title font-bold mb-3 text-white pb-3">We're doing more for merchants than ever before.</h2>
                    <p className="font-serif text-left text-white text-xl leading-8">
                        For nearly thirty years we've been providing merchants with fast and effective payment processing at 
                        some of the industry's most affordable rates. Today we're doing more for them than ever before. Our 
                        burgeoning suite of innovative products, solutions and services can now help merchants build their businesses 
                        in other new and exciting ways. After all, your payment processor should boost your bottom line — not break it.
                    </p>
                </div>
            </section>
            <section className="bg-gray-100 pt-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 flex justify-center">
                        <div className="relative">
                            <img className="absolute bottom-0" style={{maxHeight:'100%', maxWidth:'365px', width:'auto'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649689018/cwa/assets/images/Merchants/merchant-bank_p12m0a.png"/>
                        </div>
                        <div className="py-10">
                            <h2 className="cw-text-lightblue section-title font-bold mb-3">It's Who You Know</h2>
                            <p className="py-4 text-xl leading-8 font-serif">
                                CWA is an Independent Sales Organization sponsored by Merrick Bank, the top 15th 
                                largest acquiring bank in the U.S. as rated by the Nilson Report in 2018. CWA offers expedited underwriting, 
                                competitive rates and a suite of products tailored to each merchant's individual needs.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="cw-bg-lightblue pt-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10">
                            <h2 className="text-white section-title font-bold mb-3">Versatile Solutions</h2>
                            <p className="text-white py-4 text-xl leading-8 font-serif">
                                Cash transactions are quickly becoming a thing of the past. With our fast and reliable payment processing solutions, 
                                you can start accepting all major credit cards and debit cards anywhere you do business. We are now offering complete 
                                EMV and ApplePay compatible terminals to our merchants.
                            </p>
                        </div>
                        <div className="flex justify-end relative">
                            <img className="absolute bottom-0" style={{maxHeight:'100%', maxWidth:'365px', width:'auto'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649689014/cwa/assets/images/Merchants/merchant-solutions_kp1jev.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="pt-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 flex justify-center">
                        <div className="relative">
                            <img className="" style={{maxHeight:'100%', maxWidth:'365px', width:'auto'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649689012/cwa/assets/images/Merchants/merchant-support_tisp5y.png"/>
                        </div>
                        <div className="py-10">
                            <h2 className="cw-text-lightblue section-title font-bold mb-3">Unsurpassed Services</h2>
                            <p className="py-4 text-xl leading-8 font-serif">
                                We don't just help you do more business. We help you do better business. From our flexible financial services to our 
                                incredible support network, we'll see to it that your business reaches its full potential.
                            </p>
                            {/* <button className="cw-bg-lightblue rounded p-3 font-semibold text-white text-lg">Learn more</button> */}
                        </div>
                    </div>  
                </div>
            </section>
            <section className="bg-gray-100 py-20 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10">
                            <h2 className="cw-text-lightblue section-title font-bold mb-3">You make the referral — we'll make it worth your while</h2>
                            <p className="py-4 text-xl leading-8 font-serif">
                                Are there more business owners in your family or circle of friends? Refer another merchant to us and we'll credit money 
                                to your account when they sign up.
                            </p>
                            {/* <button className="cw-bg-lightblue rounded p-3 font-semibold text-white text-lg">Learn more</button> */}
                        </div>
                        <div className="relative">
                            <img className="mx-auto" style={{maxHeight:'100%', maxWidth:'365px', width:'auto'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649689010/cwa/assets/images/Merchants/merchant-handshake_fwpmmf.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="cw-bg-lightblue">
                <div className="px-5 text-center py-5 align-center max-w-5xl mx-auto space-y-3">
                    <h3 className="text-white text-xl font-serif leading-8">Ready to work with the most reliable payment processor for your business?</h3>
                    <button onClick={()=>setModal(true)} className="transition ease-in-out delay-150 border-2 border-white p-2 text-white hover:bg-white hover:text-blue-400 font-semibold rounded text-lg">Contact for more information</button>
                </div>
            </section>
            </Layout>
        </div>
    );
}

export default Index;